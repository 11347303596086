
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/test/[experimentKey]/variant_c",
      function () {
        return require("private-next-pages/test/[experimentKey]/variant_c/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/test/[experimentKey]/variant_c"])
      });
    }
  